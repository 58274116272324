import React from "react";
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  scales: {
    y: {
      display: true,
      title: {
        display: true,
        text: "(tCO2e)",
        color: "black",
        font: {
          size: 16,
          weight: 500,
        },
      },
      ticks: {
        callback: function (value, index, ticks) {
          return value + "K";
        },
        major: true,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};

export const lineChartData = {
  labels: [
    "Sep",
    "Oct",
    "Nov",
    "Dec",
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
  ],
  datasets: [
    {
      label: "water",
      data: [2, 3, 2, 3, 2, 1, 2, 3, 3, 1, 2, 3],
      borderColor: "#2B4D70",
      backgroundColor: "#2B4D70",
      pointRadius: 4,
      pointHoverRadius: 5,
      hitRadius: 2,
      cursor: "pointer",
    },
    {
      label: "Electricity",
      data: [4, 5, 4, 6, 5, 2, 4, 5.8, 4, 5.8, 6, 5],
      borderColor: "#00B783",
      backgroundColor: "#00B783",
      pointRadius: 4,
      pointHoverRadius: 5,
      hitRadius: 2,
    },
    {
      label: "Fuel",
      data: [1, 2, 3, 4, 4, 4.5, 5, 4, 5, 4, 5, 4],
      borderColor: "#D19631",
      backgroundColor: "#D19631",
      pointRadius: 4,
      pointHoverRadius: 5,
    },
    {
      label: "Domestic gas",
      data: [3, 1, 0.8, 2, 1, 3, 2, 1, 3.5, 2, 3, 2.5],
      borderColor: "#3C8DB9",
      backgroundColor: "#3C8DB9",
      pointRadius: 4,
      pointHoverRadius: 5,
    },
    {
      label: "Waste",
      data: [6, 4, 5.8, 5, 3, 5.8, 3, 2, 2.1, 5, 4, 1.2],
      borderColor: "#A68A64",
      backgroundColor: "#A68A64",
      pointRadius: 4,
      pointHoverRadius: 5,
    },
  ],
};

// const toggle

const LineChart = ({ data }) => {
  return <Line options={options} data={data} />;
};

export default LineChart;
