const Card = ({ title, options, children }) => {
  return (
    <div className="w-full flex justify-around items-center h-full bg-white flex-col p-5 rounded-2xl min-h-[300px]">
      <div className="w-full flex items-center justify-between">
        <span className="font-inter xs:text-[12px] lg:text-[18px] sm:text-[14px] md:text-[16px] font-bold leading-5">{title}</span>
        {options && options}
      </div>
      {children}
    </div>
  );
};

export default Card;
