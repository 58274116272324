import React, { useState } from "react";
import { months } from "../../Constants/months";
import Select from "react-select";
import Groups from "../../Icons/Groups";
import Card from "../../Components/Card/Card";
import HBarChart from "../../Components/HBarChart/HBarChart";
import DoughnutChart, {
  chartItems,
} from "../../Components/DoughnutChart/DoughnutChart";
import LineChart, { lineChartData } from "../../Components/LineChart/LineChart";
import ZoomableSVG from "../../Components/TestMapsWidthDots/TestZoom";

const mapIndicator = [
  {
    id: 4,
    indicator: <div className="w-[20px] h-[20px] rounded-full bg-[#B22222]" />,
    title: "Very High",
  },
  {
    id: 3,
    indicator: <div className="w-[15px] h-[15px] rounded-full bg-[#B22222]" />,
    title: "High",
  },
  {
    id: 2,
    indicator: <div className="w-[12px] h-[12px] rounded-full bg-[#B22222]" />,
    title: "Mid",
  },
  {
    id: 1,
    indicator: <div className="w-[8px] h-[8px] rounded-full bg-[#B22222]" />,
    title: "Low",
  },
];

const Home = () => {
  const [chartData, setChartData] = useState(lineChartData);

  const toggleData = (dataIndex) => {
    const updatedData = {
      ...chartData,
      datasets: chartData.datasets.map((dataset, i) => {
        if (i === dataIndex) {
          return {
            ...dataset,
            hidden: !dataset.hidden,
          };
        }
        return dataset;
      }),
    };

    setChartData(updatedData);
  };
  return (
    <div>
      <div className="w-full h-[100px] flex items-center px-[48px] justify-between">
        <Select
          options={months}
          defaultValue={{ value: "Aug", label: "August" }}
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              width: "195px",
            }),
          }}
          classNamePrefix="select"
        />
        <div className="flex">
          <Groups />
          <span className="mx-2 font-inter text-[18px] font-medium">
            700,000 Accounts
          </span>
        </div>
      </div>
      <div className="flex flex-col gap-5 px-[48px]">
        <div className="flex xs:flex-col lg:flex-row sm:flex-col md:flex-col gap-3 flex-row w-full">
          <div className="xs:w-[100%] lg:w-[60%] sm:w-[100%] md:w-[100%]">
            <Card title="CO₂ Emissions Heat Map">
              <ZoomableSVG />
              <div className="flex w-full justify-center">
                {mapIndicator.map((item) => (
                  <div className="flex items-center mx-3">
                    <div className="mr-1">{item.indicator}</div>
                    <span className="font-inter">{item.title}</span>
                  </div>
                ))}
              </div>
            </Card>
          </div>
          <div className="xs:w-[100%] lg:w-[40%] sm:w-[100%] md:w-[100%]">
            <Card title="CO₂ Emissions per Emirates">
              <HBarChart />
            </Card>
          </div>
        </div>
        <div className="flex gap-3 xs:flex-col lg:flex-row sm:flex-col md:flex-col flex-row w-full">
          <div className="xs:w-[100%] lg:w-[60%] sm:w-[100%] md:w-[100%]">
            <Card
              title="UAE CO₂ Emissions by Period"
              options={
                <div className="flex justify-around xs:w-[60%] lg:w-[55%] sm:w-[60%] md:w-[60%] items-center">
                  {chartItems.map((option, i) => (
                    <div
                      key={option.id}
                      className="flex items-center mx-1 cursor-pointer"
                      onClick={() => toggleData(i)}
                    >
                      <div
                        className={`w-[10px] h-[10px] mr-1 rounded-full`}
                        style={{ backgroundColor: option.color }}
                      />
                      <span
                        className="font-inter xs:text-[8px] lg:text-[16px] sm:text-[12px] md:text-[14px] font-normal"
                        style={{
                          textDecoration: chartData.datasets[i].hidden
                            ? "line-through"
                            : "none",
                        }}
                      >
                        {option.title}
                      </span>
                    </div>
                  ))}
                </div>
              }
            >
              <LineChart data={chartData} />
            </Card>
          </div>
          <div className="xs:w-[100%] lg:w-[40%] sm:w-[100%] md:w-[100%]">
            <Card title="UAE Total CO₂ Emissions">
              <DoughnutChart />
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
