import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const labels = [
  "Abu Dhabi",
  "Dubai",
  "Sharjah",
  "Ajman",
  "Umm Al Quwain",
  "Ras Al Khaimah",
  "Fujairah",
];

export const options = {
  indexAxis: "y",
  responsive: true,
  scales: {
    x: {
      display: true,
      title: {
        display: true,
        text: "(tCO2e)",
        color: "black",
        font: {
          size: 16,
          weight: 500,
        },
      },
      ticks: {
        callback: function (value, index, ticks) {
          return value + "K";
        },
        major: true,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
      // position: "right",
    },
    tooltip: {
      callbacks: {
        title: (xDatapoint) => {
          return xDatapoint.label;
        },
        label: (yDatapoint) => {
          return yDatapoint.raw + "K";
        },
      },
    },
  },
};

export const data = {
  labels,
  datasets: [
    {
      data: [6.2, 3.8, 6.3, 4.4, 2, 3.9, 7],
      borderColor: "rgb(255, 255, 255)",
      borderWidth: "5px",
      backgroundColor: (context) => {
        const ctx = context.chart.ctx;
        const gradient = ctx.createLinearGradient(500, 0, 0, 0);
        gradient.addColorStop(0, "rgb(43, 77, 112)");
        gradient.addColorStop(1, "rgb(0, 183, 131)");

        return gradient;
      },

      barThickness: 12,
      borderRadius: 10,
    },
  ],
};

const HBarChart = () => {
  return <Bar options={options} data={data} />;
};

export default HBarChart;
