import React from "react";
import Logo from "../../Icons/Logo";
import Management from "../../Icons/Management";
import Reward1 from "../../Icons/Reward1";
import Reward2 from "../../Icons/Reward2";

const navLinks = [
  {
    id: 1,
    title: "Roles Management",
    icon: <Management />,
  },
  {
    id: 2,
    title: "Rewards Management",
    icon: <Reward2 />,
  },
  {
    id: 3,
    title: "",
    icon: <Reward1 />,
  },
];

const Header = () => {
  return (
    <div className="flex w-full h-[80px] justify-center bg-white">
      <div className="flex w-full xs:px-[8px] lg:px-[48px] sm:px-[10px] md:px-[14px] justify-between items-center">
        <Logo />
        <div className="flex xs:w-[60%] lg:w-[50%] sm:w-[70%] md:w-[70%] justify-center items-center">
          {navLinks.map((item, index) => (
            <div key={item.id} className="flex">
              <div className="flex mx-[16px] font-inter xs:text-[10px] lg:text-[16px] sm:text-[14px] md:text-[14px] font-medium">
                {item.icon}
                <span className="mx-2">{item.title}</span>
              </div>
              {index === 1 && <div className="w-[1px] h-[24px] bg-[#E7ECF0]" />}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Header;
