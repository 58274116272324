import React from "react";

const MapLocations = ({ fillColor = "#B22222", styles }) => {
  return (
    <svg
      width="392"
      height="282"
      viewBox="0 0 392 282"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={styles}
    >
      <circle cx="187" cy="151" r="4" fill={fillColor} />
      <circle cx="138" cy="174" r="4" fill={fillColor} />
      <circle cx="100" cy="170" r="4" fill={fillColor} />
      <circle cx="297" cy="73" r="11" fill={fillColor} />
      <circle cx="12" cy="165" r="4" fill={fillColor} />
      <circle cx="296" cy="232" r="4" fill={fillColor} />
      <circle cx="316" cy="88" r="6" fill={fillColor} />
      <circle cx="363" cy="92" r="7" fill={fillColor} />
      <circle cx="380" cy="76" r="11" fill={fillColor} />
      <circle cx="375" cy="46" r="7" fill={fillColor} />
      <circle cx="357" cy="23" r="7" fill={fillColor} />
      <circle cx="329" cy="38" r="9" fill={fillColor} />
      <circle cx="316" cy="57" r="7" fill={fillColor} />
      <circle cx="358" cy="63" r="7" fill={fillColor} />
      <circle cx="296" cy="126" r="4" fill={fillColor} />
      <circle cx="342" cy="158" r="7" fill={fillColor} />
    </svg>
  );
};

export default MapLocations;
