import React, { useState } from "react";
import TestMapsWidthDots from "./TestMapsWidthDots";
import Select from "react-select";
import { months } from "../../Constants/months";
import TotalIcon from "../../Icons/TotalIcon";

const options = [
  { value: "total", label: "Total", customAbbreviation: <TotalIcon /> },
  { value: "Water", label: "Water", customAbbreviation: <TotalIcon /> },
  {
    value: "Electricity",
    label: "Electricity",
    customAbbreviation: <TotalIcon />,
  },
  { value: "Fuel", label: "Fuel", customAbbreviation: <TotalIcon /> },
  {
    value: "Domestic gas",
    label: "Domestic gas",
    customAbbreviation: <TotalIcon />,
  },
  { value: "Waste", label: "Waste", customAbbreviation: <TotalIcon /> },
];
const formatOptionLabel = ({ value, label, customAbbreviation }) => (
  <div className="flex items-center mr-1">
    <div className="flex items-center mr-1">{customAbbreviation}</div>
    <div className="text-[12px]">{label}</div>
  </div>
);

const ZoomableSVG = () => {
  const [zoom, setZoom] = useState(1);
  const [pan, setPan] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);

  const handleZoomIn = () => setZoom(zoom * 1.2);
  const handleZoomOut = () => {
    // Prevent zooming out beyond the original width
    const newZoom = zoom / 1.2;
    if (newZoom * width >= width) {
      setZoom(newZoom);
    }
  };
  const handleMouseDown = (event) => {
    setIsDragging(true);
    event.preventDefault(); // Prevent text selection during drag
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (event) => {
    if (isDragging) {
      setPan({
        x: pan.x + event.movementX,
        y: pan.y + event.movementY,
      });
    }
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
  };

  const width = 500;
  const height = 300;

  return (
    <div className="flex w-full items-center">
      <div className="w-20% h-full self-start flex items-start justify-center">
        <Select
          defaultValue={{
            value: "total",
            label: "Total",
            customAbbreviation: <TotalIcon />,
          }}
          formatOptionLabel={formatOptionLabel}
          options={options}
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              width: "130px",
              border: "none",
            }),
            indicatorSeparator: () => {},
          }}
          classNamePrefix="select"
        />
      </div>
      <div
        style={{
          width: "100%",
          height: "330px",
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "move",
        }}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      >
        <svg width="100%" height="100%" viewBox={`0 0 ${width} ${height}`}>
          <g transform={`translate(${pan.x},${pan.y}) scale(${zoom})`}>
            <TestMapsWidthDots />
          </g>
        </svg>
      </div>
      <div className="h-[105px] w-[45px] flex flex-col justify-around items-center shadow-md shadow-gray-300">
        <div className="h-">
          <button onClick={handleZoomIn} className="text-lg">
            +
          </button>
        </div>
        <div>
          <button onClick={handleZoomOut} className="text-lg">
            -
          </button>
        </div>
      </div>
    </div>
  );
};

export default ZoomableSVG;
