import React from "react";
import Map from "../../Icons/Map";
import MapLocations from "../../Icons/MapLocations";

const TestMapsWidthDots = () => {
  return (
    <svg height={280} width={392}>
      <Map />
      <MapLocations />
    </svg>
  );
};

export default TestMapsWidthDots;
