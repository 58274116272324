import { createBrowserRouter } from "react-router-dom";
import Home from "../Pages/Home/Home";
import { ROUTES_MAP } from "./routesMap";
import Layout from "../Components/Layout/Layout";

const routes = [
  {
    element: <Layout />,
    children: [
      {
        path: ROUTES_MAP.landing,
        element: <Home />,
      },
    ],
  },
];

export const router = createBrowserRouter(routes);
