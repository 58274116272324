import React from "react";
import { Chart as ChartJS, Tooltip, Legend, ArcElement } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export const options = {
  aspectRatio: 0.8,
  responsive: true,
  plugins: {
    legend: {
      display: false,
      // position: "right",
    },
    tooltip: {
      callbacks: {
        title: (xDatapoint) => {
          return xDatapoint.label;
        },
        label: (yDatapoint) => {
          return yDatapoint.raw + "%";
        },
      },
    },
  },
};

export const data = {
  labels: ["Water", "Electricity", "Fuel", "Domestic gas", "Waste"],
  datasets: [
    {
      // label: "# of Votes",
      data: [24.14, 29.17, 18.84, 16.21, 11.65],
      backgroundColor: ["#2B4D70", "#00B783", "#D19631", "#3C8DB9", "#A68A64"],
      borderColor: ["white", "white", "white", "white", "white", "white"],
      borderRadius: 5,
      hoverBorderWidth: 0,
      borderAlign: "center",
      cutout: "70%",
    },
  ],
};

const textCenter = {
  id: "text-center",

  beforeDatasetDraw(chart, args, pluginOptions) {
    const {
      ctx,
      chartArea: { width, height },
    } = chart;
    ctx.save();
    ctx.font = "bold 16px inter";
    ctx.fillStyle = "black";
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.fillText("1,607.20", width / 2, height / 2.1);
    ctx.fillText("(tCO₂e)", width / 2, height / 1.8);
  },
};

export const chartItems = [
  {
    id: 1,
    title: "Water",
    color: "#2B4D70",
    value: "24.14",
  },
  {
    id: 2,
    title: "Electricity",
    color: "#00B783",
    value: "29.17",
  },
  {
    id: 3,
    title: "Fuel",
    color: "#D19631",
    value: "18.84",
  },
  {
    id: 4,
    title: "Domestic gas",
    color: "#3C8DB9",
    value: "16.21",
  },
  {
    id: 5,
    title: "Waste",
    color: "#A68A64",
    value: "11.65",
  },
];

const DoughnutChart = () => {
  return (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <div className="w-full h-[250px] flex justify-center items-center">
        <Doughnut data={data} plugins={[textCenter]} options={options} />
      </div>
      <div className="w-full flex flex-col">
        {chartItems.map((item) => (
          <div
            key={item.id}
            className="w-full px-5 flex justify-between items-center my-1"
          >
            <div className="flex items-center">
              <div
                className={`w-[10px] h-[10px] mr-2 rounded-full `}
                style={{ backgroundColor: item.color }}
              />
              <span className="font-inter">{item.title}</span>
            </div>
            <span className="font-inter bg-[#FBF7F1]">{item.value}%</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DoughnutChart;
